#login {
  // background-color: #ed7600;
  padding: 8% 30px 30px 30px !important;
}
.login h1 a {

  background-image: url('../images/gtf_admin_logo.png');
  background-size: contain;
  background-position: center center;
  width: 320px;
  height: 51px;
}

div#login_error { display: none; }
#backtoblog { display: none; }
